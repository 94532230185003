import { AgendaTemplateData } from './AgendaTemplateData';
import { AgendaImportTemplate } from './AgendaImportTemplate';

export const getAgendaTemplate = (
  agendas: {
    biography?: string;
    date: Date;
    id: string;
    instructorCompanyName?: string;
    instructorName?: string;
    title: string;
    time: Date[];
  }[],
  title: string,
  place: string,
  dateFrom: Date,
  dateTo: Date
) => {
  const sortedAgendas = agendas.sort((a, b) =>
    new Date(a.time[0] as Date).getTime() > new Date(b.time[0] as Date).getTime() ? 1 : -1
  );
  return AgendaImportTemplate(prepareData(sortedAgendas, title, place, dateFrom, dateTo));
};

const prepareData = (
  agendas: {
    biography?: string;
    date: Date;
    id: string;
    instructorCompanyName?: string;
    instructorName?: string;
    title: string;
    time: Date[];
  }[],
  title: string,
  place: string,
  dateFrom: Date,
  dateTo: Date
) => {
  const templateData = {
    agendas: [...agendas],
    title: title,
    place: place,
    dateFrom: new Date(dateFrom),
    dateTo: new Date(dateTo)
  };

  return templateData;
};
