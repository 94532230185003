import { useState, useEffect, ChangeEvent, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import PersonalDataIcon from '../../assets/personal-data-icon.png';
import AccommodationIcon from '../../assets/accommodation-icon.png';
import './Individual.scss';
import { Form, Button, Input, Select, Checkbox, Space, Modal } from 'antd';
import AddNewAccommodation from '../AddNewAccommodation/AddNewAccommodation';
import { PlusOutlined } from '@ant-design/icons';
import { CreatedType, RegistrationStatus } from '../../models/Registration';
import { createRegistration } from '../../services/RegistrationService';
import { getAllEvents } from '../../services/EventService';
import { addThousandSeparator, phoneRegex, validatePersonalId } from '../../core/Utility';
import { EventDto, EventListDto } from '../../models/Event';
import { AccommodationDto, AdditionalPersonDto } from '../../models/Accommodation';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import ReCAPTCHA from 'react-google-recaptcha';
import { Config } from '../../config/config';
import useGoogleReCaptchaV3 from '../../hooks/UseGoogleRecaptcha3';
import { validateRecaptcha } from '../../services/RecaptchaService';
import { Toast } from 'primereact/toast';

const Individual = (props: any) => {
  // eslint-disable-next-line prefer-const
  const [form] = Form.useForm();
  const [showFirst, setShowFirst] = useState<boolean>(true);
  const [showSecond, setShowSecond] = useState<boolean>(false);
  const [isAccommodationRequired, setNeedAccommodation] = useState<boolean>(false);
  const [oneEvent, setOneEvent] = useState<EventDto>();
  const [allEvents, setAllEvents] = useState<EventListDto[]>([]);
  const [getData, setGetData] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [getNewPriceData, setGetNewPriceData] = useState<boolean>(false);
  const registrationFee = oneEvent?.registrationFee ?? 0;
  const [status, setStatus] = useState<any>(undefined);
  const [textMessage, setTextMessage] = useState<any>(undefined);
  const [phoneError, setPhoneError] = useState(false);
  const toast = useRef<any>(null);
  const [showInfoModal, setShowInfoModal] = useState(false);

  const [fieldsFormData, setFieldsFormData] = useState({ country: 'Srbija' });

  const documentType = [
    { label: 'Učesnik na konferenciji', value: 'Učesnik' }
    // { label: 'Gost(ne prisustvuje konferenciji)', value: 'Gost' }
  ];

  const { executeReCaptcha } = useGoogleReCaptchaV3({ siteKey: Config.reCaptchaKey });
  const Country = [
    { label: 'Srbija', value: 'Srbija' },
    { label: 'Inostranstvo', value: 'Inostranstvo' }
  ];

  const accommodationArray = Array.from({ length: 1 }, (_, index) => index).map((el: any) => {
    return;
  });

  useEffect(() => {
    if (!status) return;
    if (status !== 409) {
      setStatus(undefined);
      setShowInfoModal(true);
      setNeedAccommodation(false);
      setGetData(false);
      return form.resetFields();
    } else {
      toast.current.show({
        severity: 'error',
        detail: `Već postoji uneti broj telefona ${textMessage}.`,
        life: 3000
      });

      setStatus(undefined);
    }
  }, [status]);

  useEffect(() => {
    const values = form.getFieldValue('accommodations');
    if (values?.length === 0) {
      handleTotalPrice(0);
      setGetData(false);
    }
  }, [getNewPriceData]);

  useEffect(() => {
    if (isAccommodationRequired) {
      if (getData) {
        const FieldValues = form.getFieldsValue();
        const newData: any = FieldValues;
        if (newData.accommodations && newData.accommodations.length !== 0) {
          const personBearer = { ...newData };
          delete personBearer.accommodations;
          newData.accommodations[0].additionalPerson[0] = personBearer;
          if (!newData.accommodations[0].numberOfPersons) {
            newData.accommodations[0].numberOfPersons = 1;
          }
        }
        form.setFieldValue('accommodations', newData.accommodations);
        handleGetPriceData(true);
      } else {
        form.setFieldValue('accommodations', accommodationArray);
      }
    } else {
      const fieldsFormData = form.getFieldValue('type');
      if (fieldsFormData === 'Učesnik') {
        handleTotalPrice(registrationFee);
      } else {
        handleTotalPrice(0);
      }
    }
  }, [isAccommodationRequired, getData, fieldsFormData]);

  useEffect(() => {
    setOneEvent(props.data);
  }, [props.data]);

  useEffect(() => {
    getAllEvents().then((eventResponse) => {
      const newEventOptions: { label: string; value: string; id: string }[] = [];
      eventResponse.data.result?.map((eventItem: EventDto) => {
        newEventOptions.push({
          label: eventItem.name,
          value: eventItem.name,
          id: eventItem.id
        });
      });
      setAllEvents(newEventOptions);
    });
  }, []);

  const handleFieldsChanges = (e: any, name: string) => {
    const delayDebounceFn = setTimeout(() => {
      setFieldsFormData({ ...fieldsFormData, [name]: e.target.value });
    }, 300);
    return () => clearTimeout(delayDebounceFn);
  };

  const handleGetPriceData = (get: boolean) => {
    setGetNewPriceData(get);
  };

  const handleSelectionChanges = (e: any, name: string) => {
    setFieldsFormData({ ...fieldsFormData, [name]: e });
  };

  const handleGetData = () => {
    setGetData(true);
  };

  const handleCheckboxGroup = (e: ChangeEvent<HTMLInputElement>, name: string) => {
    if (name === 'noAccommodation') {
      form.setFieldValue('isAccommodationRequired', false);
      // form.setFieldValue('accommodations', []);
      setNeedAccommodation(false);
      setShowSecond(false);
      // setGetData(false);
    } else {
      form.setFieldValue('noAccommodation', false);
      setNeedAccommodation(e.target.checked);
      setShowSecond(true);
    }
  };

  const handleTotalPrice = (price: any) => {
    setTotalPrice(price);
  };

  const handleShowFirstAccordion = () => {
    return setShowFirst(!showFirst);
  };

  const handleShowSecondAccordion = () => {
    return setShowSecond(!showSecond);
  };

  const handleConfirmSaveForm = async () => {
    const token = await executeReCaptcha('LoadReCaptchaOnClick');
    const validationResponse = await validateRecaptcha(token);
    if (!(validationResponse.data.result.success && validationResponse.data.result.score > 0.5)) {
      alert('Preveliki broj poziva');
      return;
    }

    const fieldValuesAccommodations = form.getFieldValue('accommodations');

    let accommodationAdditionalPersonLength = 0;

    if (isAccommodationRequired) {
      fieldValuesAccommodations?.map((el: any) => {
        if (el?.additionalPerson?.length > 0) {
          return (accommodationAdditionalPersonLength =
            accommodationAdditionalPersonLength + el.additionalPerson.length);
        }
      });
    } else {
      return handleSaveForm();
    }

    if (accommodationAdditionalPersonLength !== 0) {
      return handleSaveForm();
    } else {
      toast.current.show({
        severity: 'error',
        detail: 'Nije moguće kreirati prijavu bez učesnika!',
        life: 3000
      });
    }
  };

  const handleSaveForm = async () => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        const formFieldsContent = form.getFieldsValue();

        let eventXRoomType = {};

        let accommodatonID = {};

        delete formFieldsContent['noAccommodation'];

        const singleEvent = allEvents.filter((el: EventListDto) => {
          if (el.id === props.idEvent) {
            return { id: el.id, name: el.value };
          }
        });

        formFieldsContent.event = { id: singleEvent[0]?.id, name: singleEvent[0]?.value };

        if (formFieldsContent?.accommodations) {
          const roomIdArray = formFieldsContent?.accommodations?.map(
            (el1: AccommodationDto, i: number) => {
              return el1.roomId;
            }
          );

          const numberOfDaysData = formFieldsContent?.accommodations?.map(
            (el1: AccommodationDto, i: number) => {
              return el1.numberOfDays;
            }
          );

          const priceOfAccommodationData = formFieldsContent?.accommodations?.map(
            (el1: AccommodationDto, i: number) => {
              return el1.priceOfAccommodation / Number(el1.numberOfPersons);
            }
          );

          const parkingPlaceData = formFieldsContent?.accommodations?.map(
            (el1: AccommodationDto, i: number) => {
              return el1.parkingPlaces;
            }
          );

          const roomTypeIdData = formFieldsContent?.accommodations?.map(
            (el1: AccommodationDto, i: number) => {
              return el1.roomType;
            }
          );

          const dateArray = formFieldsContent?.accommodations?.map(
            (el1: AccommodationDto, i: number) => {
              return el1.date;
            }
          );

          formFieldsContent.date = dateArray[0];
          formFieldsContent.roomId = roomIdArray[0];
          formFieldsContent.roomTypeId = roomTypeIdData[0];
          formFieldsContent.numberOfDays = numberOfDaysData[0];
          formFieldsContent.priceOfAccommodation = priceOfAccommodationData[0];
          formFieldsContent.parkingPlace = parkingPlaceData[0];
        }

        formFieldsContent?.accommodations?.map((el1: AccommodationDto, i: number) => {
          el1?.additionalPerson?.map((el: AdditionalPersonDto, j: number) => {
            // eslint-disable-next-line prefer-const
            eventXRoomType = { id: el1.roomType };
            accommodatonID = { id: el1?.accommodationId };
            const copyOfNewEl = {
              ...el,
              status: RegistrationStatus.Pending,
              lastStatusChange: new Date(),
              roomId: el1.roomId,
              eventXRoomType: { id: el1.roomType },
              accommodation: { id: el1.accommodationId },
              event: props.idEvent,
              roomTypeId: el1.roomType,
              numberOfDays: el1.numberOfDays,
              priceOfAccommodation: Number(el1.priceOfAccommodation) / Number(el1.numberOfPersons),
              parkingPlace: el1.parkingPlaces,
              createdType: CreatedType.Site
            };
            formFieldsContent.accommodations[i].additionalPerson[j] = copyOfNewEl;
          });
        });

        formFieldsContent.eventXRoomType = eventXRoomType;

        formFieldsContent.accommodation = accommodatonID;

        formFieldsContent.createdType = CreatedType.Site;

        const completeRegistration = {
          ...formFieldsContent,
          status: RegistrationStatus.Pending,
          isCompanyRegistration: false,
          lastStatusChange: new Date()
        };

        createRegistration(completeRegistration)
          .then((response) => {
            setStatus(response.status);
            setTextMessage('');
          })
          .catch((err: any) => {
            let text = err.response.data.message.match(/\d/g);
            text = text.join('');
            setTextMessage(text);
            setStatus(err.response.status);
          });
      },
      () => {
        console.log('');
      }
    );
  };

  return (
    <div className="individual">
      <Toast ref={toast} position={'top-right'} />
      <Form form={form} name="new-individual-form" layout="vertical" autoComplete="off">
        <div className="accordion">
          <div
            onClick={() => {
              handleShowFirstAccordion();
            }}
            className={'accordion-item'}>
            <div className="header-box">
              <img src={PersonalDataIcon} />
              <p className="accordion-header" style={{ marginLeft: '10px' }}>
                Podaci o učesniku
              </p>
            </div>
            <div style={{ marginRight: '10px' }} className="button-open">
              {showFirst ? (
                <FontAwesomeIcon icon={faChevronUp} color={'white'} />
              ) : (
                <FontAwesomeIcon icon={faChevronDown} color={'white'} />
              )}
            </div>
          </div>
        </div>

        <div className={showFirst ? 'accordion-body' : 'close'}>
          <div className="accordion-body-inner">
            <Form.Item
              className="form-box"
              name={'firstName'}
              label="Ime:"
              rules={[
                {
                  required: true,
                  message: 'Popuniti obavezno polje!'
                }
              ]}>
              <Input
                onChange={(e) => handleFieldsChanges(e, 'firstName')}
                className="input-label"
                placeholder="Unesite ime"
              />
            </Form.Item>
            <Form.Item
              className="form-box"
              name={'lastName'}
              label="Prezime:"
              rules={[
                {
                  required: true,
                  message: 'Popuniti obavezno polje!'
                }
              ]}>
              <Input
                onChange={(e) => handleFieldsChanges(e, 'lastName')}
                className="input-label"
                placeholder="Unesite prezime"
              />
            </Form.Item>
            <Form.Item
              name={'type'}
              label="Tip učesnika:"
              rules={[
                {
                  required: true,
                  message: 'Popuniti obavezno polje!'
                }
              ]}>
              <Select
                onChange={(e) => handleSelectionChanges(e, 'type')}
                placeholder="Tip učesnika"
                options={documentType}
              />
            </Form.Item>
            <Form.Item
              className="form-box"
              name={'country'}
              label="Zemlja:"
              rules={[
                {
                  required: true,
                  message: 'Popuniti obavezno polje!'
                }
              ]}>
              <Select
                onChange={(e) => handleSelectionChanges(e, 'country')}
                placeholder="Izaberite Zemlju"
                options={Country}
              />
            </Form.Item>
            <Form.Item className="form-box" name={'city'} label="Grad:">
              <Input
                onChange={(e) => handleFieldsChanges(e, 'city')}
                className="input-label"
                placeholder="Unesite grad"
              />
            </Form.Item>
            <Form.Item className="form-box" name={'street'} label="Ulica:">
              <Input
                onChange={(e) => handleFieldsChanges(e, 'street')}
                className="input-label"
                placeholder="Unesite ulicu"
              />
            </Form.Item>
            <Form.Item className="form-box" name={'streetNumber'} label="Broj:">
              <Input
                onChange={(e) => handleFieldsChanges(e, 'streetNumber')}
                className="input-label"
                placeholder="Unesite broj"
              />
            </Form.Item>
            <Form.Item
              className="form-box"
              name={'email'}
              label="E-mail:"
              rules={[
                {
                  required: true,
                  message: 'Popuniti obavezno polje!'
                },
                {
                  type: 'email',
                  message: 'Unesite ispravan format e-maila!',
                  validateTrigger: 'submit'
                }
              ]}>
              <Input
                onChange={(e) => handleFieldsChanges(e, 'email')}
                className="input-label"
                placeholder="Unesite e-mail adresu"
              />
            </Form.Item>
            <div className="phone-input-container">
              <Form.Item
                className="form-box"
                name={'phone'}
                label="Mobilni telefon:"
                rules={[
                  {
                    required: true,
                    message: ''
                  },
                  {
                    validateTrigger: 'onChange',
                    validator: async (_, value) => {
                      if (value === undefined || value === null) {
                        return setPhoneError(true);
                      } else {
                        if (!phoneRegex.test(value)) {
                          setPhoneError(true);
                          return Promise.reject();
                        } else {
                          setPhoneError(false);
                          return Promise.resolve();
                        }
                      }
                    }
                  }
                ]}>
                <PhoneInput
                  // placeholder="Unesite broj telefona"
                  onChange={(e) => handleSelectionChanges(e, 'phone')}
                  className={phoneError ? 'phone-input-error' : 'phone-input'}
                />
              </Form.Item>
              {
                <p className={!phoneError ? 'phone-description' : 'phone-description-no-visible'}>
                  Unesite ispravan format +381 66 12345678
                </p>
              }
            </div>
            {/* {fieldsFormData.country === 'Srbija' ? (
              <>
                <Form.Item
                  className="form-box"
                  name={'nationalId'}
                  label="Broj lične karte:"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: 'Potrebno je uneti broj lične karte!'
                  //   }
                  // ]}
                >
                  <Input
                    onChange={(e) => handleFieldsChanges(e, 'nationalId')}
                    type="number"
                    className="input-label"
                    placeholder="Unesite broj lične karte "
                  />
                </Form.Item>
                <Form.Item
                  className="form-box"
                  name={'personalId'}
                  label="JMBG:"
                  rules={[
                    // {
                    //   required: true,
                    //   message: 'Potrebno je uneti JMBG!'
                    // },
                    {
                      validateTrigger: 'submit',
                      validator: async (_, value) => {
                        if (value === undefined || value === null) return;
                        if (!validatePersonalId(value)) {
                          return Promise.reject(new Error('Unesite ispravan JMBG'));
                        } else {
                          return Promise.resolve();
                        }
                      }
                    }
                  ]}>
                  <Input
                    onChange={(e) => handleFieldsChanges(e, 'personalId')}
                    type="number"
                    className="input-label"
                    placeholder="Unesite JMBG"
                  />
                </Form.Item>
              </>
            ) : (
              <Form.Item
                className="form-box"
                name={'nationalId'}
                label="Broj pasoša:"
                // rules={[
                //   {
                //     required: true,
                //     message: 'Potrebno je uneti broj pasoša!'
                //   }
                // ]}
              >
                <Input
                  onChange={(e) => handleFieldsChanges(e, 'nationalId')}
                  type="number"
                  className="input-label"
                  placeholder="Unesite broj pasoša "
                />
              </Form.Item>
            )} */}
            <div className="checkbox-group">
              <Form.Item name="isAccommodationRequired" valuePropName="checked">
                <Checkbox
                  onChange={(e: any) => handleCheckboxGroup(e, 'isAccommodationRequired')}
                  className="checkbox">
                  <p className="checkbox-text">Potreban smeštaj</p>
                </Checkbox>
              </Form.Item>
              <Form.Item name="noAccommodation" valuePropName="checked">
                <Checkbox
                  onChange={(e: any) => handleCheckboxGroup(e, 'noAccommodation')}
                  className="checkbox">
                  <p className="checkbox-text">Bez smeštaja</p>
                </Checkbox>
              </Form.Item>
            </div>
          </div>
        </div>

        <div className="accordion">
          <div
            onClick={() => {
              handleShowSecondAccordion();
            }}
            className={isAccommodationRequired ? 'accordion-item' : 'accordion-item-disabled'}>
            <div className="header-box">
              <img src={AccommodationIcon} />
              <p className="accordion-header" style={{ marginLeft: '10px' }}>
                Smeštaj
              </p>
            </div>
            <div style={{ marginRight: '10px' }} className="button-open">
              {isAccommodationRequired ? (
                <FontAwesomeIcon icon={faChevronUp} color={'white'} />
              ) : (
                <FontAwesomeIcon icon={faChevronDown} color={'white'} />
              )}
            </div>
          </div>
        </div>
        <div className={showSecond ? 'accordion-body' : 'close'}>
          {showSecond && isAccommodationRequired ? (
            <Form.List name="accommodations">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space key={key} className="list-of-data" align="baseline">
                      <AddNewAccommodation
                        form={form}
                        needAccommodation={isAccommodationRequired}
                        name={name}
                        restField={{ ...restField }}
                        remove={remove}
                        idEvent={props.idEvent}
                        oneEvent={oneEvent}
                        handleGetData={handleGetData}
                        individual={true}
                        handleTotalPrice={handleTotalPrice}
                        getNewPriceData={getNewPriceData}
                        handleGetPriceData={handleGetPriceData}
                        registrationFee={registrationFee}
                      />
                    </Space>
                  ))}
                  {fields.length === 0 && (
                    <Form.Item className="add-new-btn">
                      <Button icon={<PlusOutlined />} type="primary" onClick={() => add()}>
                        Dodaj sobu
                      </Button>
                    </Form.Item>
                  )}
                </>
              )}
            </Form.List>
          ) : null}
        </div>

        <div className={'additional-fields'}>
          <div className="box">
            <div className="price-box">
              <p className="price-text">Cena sa PDV u RSD:</p>
              <span className="price-input">{addThousandSeparator(totalPrice)}</span>
            </div>
            {/* <Button className="btn-save-application" type="primary" danger>
              SAČUVAJ PRIJAVU
            </Button> */}
            <Button
              className="btn-save-form"
              type="primary"
              danger
              htmlType="submit"
              onClick={handleConfirmSaveForm}>
              POTVRDI PRIJAVU
            </Button>
          </div>
        </div>
        <p className="text-blue">
          **** Organizator zadržava pravo da ne prihvati prijavu bez obaveze daljeg obrazloženja.
        </p>
      </Form>
      <Modal
        open={showInfoModal}
        title={`Uspešno kreirana prijava`}
        onCancel={() => setShowInfoModal(false)}
        onOk={() => setShowInfoModal(false)}
        footer={null}>
        <p>Poštovana/i</p>
        <p className="notification-content">{`
              Vaša prijava je uspešno evidentirana u našem sistemu. Ukoliko Vaša prijava bude odobrena bićete kontaktirani.
              `}</p>
        <p>Hvala Vam na interesovanju, Vaš UOS</p>
      </Modal>
    </div>
  );
};

export default Individual;
