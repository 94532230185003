import { AgendaTemplateData } from './AgendaTemplateData';
import { headerLogo, backgroundLogo } from '../constants/PdfReport';
import moment from 'moment';

export const AgendaImportTemplate = (data: AgendaTemplateData) => {
  return {
    background: [
      {
        image: backgroundLogo,
        width: 580,
        alignment: 'center',
        absolutePosition: { x: 0, y: 150 }
      }
    ],
    header: [],
    footer: [],
    content: [
      {
        image: headerLogo,
        width: 150,
        alignment: 'center'
      },
      {
        text: `${data.title}, ${data.place}, ${moment(data.dateFrom).format('DD.MM.')} - ${moment(
          data.dateTo
        ).format('DD.MM.')}`,
        alignment: 'center',
        style: 'title'
      },
      {
        text: 'AGENDA',
        alignment: 'center',
        style: 'title',
        margin: [0, 10, 0, 25]
      },
      data.agendas?.map((item, index) => {
        return {
          text: [
            `${moment(new Date(item.date)).format('DD.MM.')} ${moment(
              new Date(item.time[0])
            ).format('HH:mm')}${','} ${item.title}`,
            {
              text: `${/*index == 13 ? '' : item.instructorName ? ', ' : */ ''} ${
                //samo za konkretan dogadjaj
                item.instructorName ?? ''
              }${/*index == 13 ? '' : item.instructorName ? ', ' : */ ''}${
                item.instructorCompanyName ?? ''
              }`,
              bold: true
            }
          ],
          style: 'oneAgenda'
        };
      })
    ],
    styles: {
      title: {
        fontSize: '15',
        alignment: 'center',
        margin: [0, 10, 0, 0],
        bold: true
      },
      logo: {
        fontSize: '18',
        bold: true
      },
      oneAgenda: {
        fontSize: '11',
        alignment: 'left',
        margin: [0, 2, 0, 0]
      }
    }
  };
};
