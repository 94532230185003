import { useState, useEffect } from 'react';
import { Form, Input, Select } from 'antd';
import Bin from '../../assets/bin.png';
import Refresh from '../../assets/refresh.png';
import './NewParticipant.scss';
import { phoneRegex, validatePersonalId } from '../../core/Utility';
import { ParticipantPropDTO } from '../../models/Participant';
import { AccommodationDto } from '../../models/Accommodation';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

const NewParticipant = (props: ParticipantPropDTO) => {
  const [phoneError, setPhoneError] = useState(false);
  const [country, setCountry] = useState('Srbija');
  const data = props?.form?.getFieldsValue();

  useEffect(() => {
    if (props.individual && props.name === 0) {
      setCountry(data.country ?? 'Srbija');
    }
  }, [data]);

  useEffect(() => {
    if (data?.accommodations) {
      data?.accommodations?.map((el: any, index: any) => {
        if (index === props.accommodationName) {
          el.additionalPerson.map((el1: any, index1: number) => {
            if (index1 === props.name) {
              if (el1?.country) {
                setCountry(el1.country);
              }
            }
          });
        }
      });
    }
    if (data?.additionalPerson) {
      data?.additionalPerson?.map((data: any, index2: number) => {
        if (index2 === props.name) {
          if (data?.country) {
            setCountry(data.country);
          }
        }
      });
    }
  }, [data]);

  const documentType = [
    { label: 'Učesnik na konferenciji', value: 'Učesnik' }
    // { label: 'Gost(ne prisustvuje konferenciji)', value: 'Gost' }
  ];

  const Country = [
    { label: 'Srbija', value: 'Srbija' },
    { label: 'Inostranstvo', value: 'Inostranstvo' }
  ];

  const handleCountryChange = (e: any) => {
    setCountry(e);
  };

  const handleDelete = () => {
    props.remove(props.name);
    props.handleGetPriceData(true);
  };

  const handleResetFields = () => {
    let values = props.form.getFieldValue('accommodations');
    props.handleGetPriceData(true);
    values = values.map((item: AccommodationDto, index: number) => {
      if (index === props.accommodationName) {
        const particioants = item?.additionalPerson?.map((participantItem: any, index: number) => {
          if (index === props.name) {
            participantItem = undefined;
          } else {
            return participantItem;
          }
        });
        return (item = { ...item, additionalPerson: particioants });
      } else {
        return item;
      }
    });

    props.form.setFieldValue('accommodations', values);
  };

  return (
    <div className="new-participant">
      <div className="inner-container-participant">
        <div className="header">
          <p className="participant-number">{`Učesnik - ${props.name + 1}`}</p>
        </div>
        <Form.Item
          {...props.restField}
          className="form-box"
          name={[props.name, 'firstName']}
          label="Ime:"
          rules={
            props.individual && props.name === 0
              ? []
              : [
                  {
                    required: true,
                    message: 'Popuniti obavezno polje!'
                  }
                ]
          }>
          <Input className="input-label" placeholder="Unesite ime" />
        </Form.Item>
        <Form.Item
          {...props.restField}
          className="form-box"
          name={[props.name, 'lastName']}
          label="Prezime:"
          rules={
            props.individual && props.name === 0
              ? []
              : [
                  {
                    required: true,
                    message: 'Popuniti obavezno polje!'
                  }
                ]
          }>
          <Input className="input-label" placeholder="Unesite prezime" />
        </Form.Item>
        <Form.Item
          name={[props.name, 'type']}
          label="Tip učesnika:"
          rules={
            props.individual && props.name === 0
              ? []
              : [
                  {
                    required: true,
                    message: 'Popuniti obavezno polje!'
                  }
                ]
          }>
          <Select
            onChange={() => props.handleGetPriceData(true)}
            placeholder="Tip učesnika"
            options={documentType}
          />
        </Form.Item>
        <Form.Item
          {...props.restField}
          className="form-box"
          name={[props.name, 'country']}
          label="Zemlja:"
          rules={
            props.individual && props.name === 0
              ? []
              : [
                  {
                    required: true,
                    message: 'Popuniti obavezno polje!'
                  }
                ]
          }>
          <Select onChange={handleCountryChange} placeholder="Izaberite Zemlju" options={Country} />
        </Form.Item>
        <Form.Item
          {...props.restField}
          className="form-box"
          name={[props.name, 'city']}
          label="Grad:">
          <Input className="input-label" placeholder="Unesite grad" />
        </Form.Item>
        <Form.Item
          {...props.restField}
          className="form-box"
          name={[props.name, 'street']}
          label="Ulica:">
          <Input className="input-label" placeholder="Unesite ulicu" />
        </Form.Item>
        <Form.Item
          {...props.restField}
          className="form-box"
          name={[props.name, 'streetNumber']}
          label="Broj:">
          <Input className="input-label" placeholder="Unesite broj" />
        </Form.Item>
        <div className="phone-input-container">
          <Form.Item
            {...props.restField}
            className="form-box"
            name={[props.name, 'phone']}
            label="Mobilni telefon:"
            rules={
              props.individual && props.name === 0
                ? []
                : [
                    {
                      required: true,
                      message: ''
                    },
                    {
                      validateTrigger: 'onChange',
                      validator: async (_, value) => {
                        if (value === undefined || value === null) {
                          return setPhoneError(true);
                        } else {
                          if (!phoneRegex.test(value)) {
                            setPhoneError(true);
                            return Promise.reject();
                          } else {
                            setPhoneError(false);
                            return Promise.resolve();
                          }
                        }
                      }
                    }
                  ]
            }>
            <PhoneInput
              className={phoneError ? 'phone-input-error' : 'phone-input'}
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              onChange={() => {}}
              // placeholder="Unesite broj telefona"
            />
          </Form.Item>
          {
            <p className={!phoneError ? 'phone-description' : 'phone-description-no-visible'}>
              Unesite ispravan format +381 66 12345678
            </p>
          }
        </div>
        <Form.Item
          {...props.restField}
          className="form-box"
          name={[props.name, 'email']}
          label="E-mail:"
          rules={
            props.individual && props.name === 0
              ? []
              : [
                  {
                    required: true,
                    message: 'Popuniti obavezno polje!'
                  },
                  {
                    validateTrigger: 'submit',
                    type: 'email',
                    message: 'Unesite ispravan format e-maila!'
                  }
                ]
          }>
          <Input className="input-label" placeholder="Unesite e-mail adresu" />
        </Form.Item>
        {/* {country === 'Srbija' ? (
          <>
            <Form.Item
              {...props.restField}
              className="form-box"
              name={[props.name, 'nationalId']}
              label="Broj lične karte:"
              rules={
                props.individual && props.name === 0
                  ? []
                  : [
                      // {
                      //   required: true,
                      //   message: 'Potrebno je uneti broj lične karte!'
                      // }
                    ]
              }>
              <Input placeholder="Unesite broj lične karte" type="number" className="input-label" />
            </Form.Item>
            <Form.Item
              {...props.restField}
              className="form-box"
              name={[props.name, 'personalId']}
              label="JMBG:"
              rules={
                props.individual && props.name === 0
                  ? []
                  : [
                      // {
                      //   required: true,
                      //   message: 'Potrebno je uneti broj lične karte!'
                      // },
                      {
                        validateTrigger: 'submit',
                        validator: async (_, value) => {
                          if (value === undefined || value === null) return;
                          if (!validatePersonalId(value)) {
                            return Promise.reject(new Error('Unesite ispravan JMBG'));
                          } else {
                            return Promise.resolve();
                          }
                        }
                      }
                    ]
              }>
              <Input placeholder="Unesite JMBG" type="number" className="input-label" />
            </Form.Item>
          </>
        ) : (
          <Form.Item
            {...props.restField}
            className="form-box"
            name={[props.name, 'nationalId']}
            label="Broj pasoša:"
            rules={
              props.individual && props.name === 0
                ? []
                : [
                    // {
                    //   required: true,
                    //   message: 'Potrebno je uneti broj pasoša!'
                    // }
                  ]
            }>
            <Input type="number" className="input-label" />
          </Form.Item>
        )} */}
        <div className="btn-container">
          <div
            className="btn"
            onClick={() => (props.accommodation ? handleResetFields() : handleDelete())}>
            <img src={props.accommodation ? Refresh : Bin} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewParticipant;
