import './Gallery.scss';
import gallery1 from '../../assets/gallery1.png';
import gallery2 from '../../assets/gallery2.png';
import gallery3 from '../../assets/gallery3.png';
import gallery4 from '../../assets/gallery4.png';
import { Link } from 'react-router-dom';

const Gallery = (props: any) => {
  return (
    <div className={`gallery ${props.galleryClass}`}>
      <p className="title">Arhiva</p>
      <p className="subtitle">PRETHODNIH DOGADJAJA</p>
      <div className="img-box">
        <Link to={'/archive'} className="image-wrapper">
          <img className="img" src={gallery1} />
        </Link>
        <Link to={'/archive'} className="image-wrapper">
          <img className="img" src={gallery2} />
        </Link>
        <Link to={'/archive'} className="image-wrapper">
          <img className="img" src={gallery3} />
        </Link>
        <Link to={'/archive'} className="image-wrapper">
          <img className="img" src={gallery4} />
        </Link>
      </div>
      <div className="btn-container">
        <Link to={'/archive'} className="btn">
          POGLEDAJTE VIŠE
        </Link>
      </div>
    </div>
  );
};

export default Gallery;
