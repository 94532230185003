import React, { useState, useEffect } from 'react';
import AgendaNavigation from '../../components/AgendaNavigation/AgendaNavigation';
import AgendaNavigationMobile from '../../components/AgendaNavigationMobile/AgendaNavigationMobile';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import MobileHeaderSmall from '../../components/MobileHeaderSmall/MobileHeaderSmall';
import ParticipationMobileApp from '../../components/ParticipationMobileApp/ParticipationMobileApp';
import Venue from '../../components/Venue/Venue';
import { AgendaTabDataSecond } from '../../data/AgendaTabDataSecond';
import './Agenda.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

const Agenda = () => {
  const [showBtn, setShowBtn] = useState(false);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 400) {
        setShowBtn(true);
      } else {
        setShowBtn(false);
      }
    });
  }, []);

  return (
    <div className="agenda">
      <Header main={false} />
      <MobileHeaderSmall main={false} />
      <AgendaNavigation
        titleClassName={'agenda-page'}
        subtitleClassName={'agenda-page-subtitle'}
        title={'7. SRPSKI DANI OSIGURANJA - KONFERENCIJSKA SALA HOTELA ZLATIBOR'}
        AgendaTabData={AgendaTabDataSecond}
      />
      <AgendaNavigationMobile />
      <ParticipationMobileApp />
      <Venue />
      <Footer />
      {showBtn && (
        <button onClick={goToTop} className="btn-top">
          <FontAwesomeIcon icon={faArrowUp} color="white" className="icon" />
        </button>
      )}
    </div>
  );
};

export default Agenda;
