import { useState, useEffect, ChangeEvent } from 'react';
import { Form, Input, Select, DatePicker, Space, Checkbox, Modal, Button, InputNumber } from 'antd';
import Bin from '../../assets/bin.png';
import './AddNewAccommodation.scss';
import { Link } from 'react-router-dom';
import NewParticipant from '../NewParticipant/NewParticipant';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import {
  AccommodationDto,
  AccommodationListDto,
  AdditionalPersonDto
} from '../../models/Accommodation';
import { RoomTypeListDto } from '../../models/RoomType';
import { EventXRoomTypeCreate } from '../../models/EventXRoomTypeDto';
import dayjs from 'dayjs';

const AddNewAccommodation = (props: any) => {
  const { RangePicker } = DatePicker;
  // eslint-disable-next-line prefer-const
  let values = props.form.getFieldValue('accommodations');
  const watchRoomtype = values[props.name]?.roomType;
  const [participantsNumber, setParticipantsNumber] = useState<number>(
    values[props.name]?.additionalPerson?.length
  );

  const [numberOfNights, setNumberOfNights] = useState<any>(values[props.name]?.numberOfDays);
  const [allAccommodationOption, setAllAccommodationOption] = useState<AccommodationListDto[]>([]);
  const [roomTypesOptions, setRoomTypesOptions] = useState<RoomTypeListDto[]>([]);
  const [dateStart, setDateStart] = useState<string>();
  const [dateEnd, setDateEnd] = useState<string>();
  const [id, setId] = useState<string>('');
  const [roomPriceForPerson, setRoomPriceForPerson] = useState<any>({});
  // const [priceOfAccommodation, setPriceOfAccommodation] = useState(
  //   values[props.name]?.priceOfAccommodation
  // );
  // const [showPriceForPerson, setShowPriceForPerson] = useState(false);
  const [newData, setNewData] = useState(false);
  const [max, setMax] = useState<any>(0);
  const min = 1;
  const [numberOfPersons, setNumberOfPersons] = useState<any>([]);
  const [openViewModal, setOpenViewModal] = useState<boolean>(false);
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const [newPersonNumber, setNewPersonNumber] = useState<any>(0);
  const [newMaxCapacity, setNewMaxCapacity] = useState<any>('');
  const [disableNumberOfPerson, setDisableNumberOfPerson] = useState(false);
  const [roomCapacity, setRoomCapacity] = useState<any>();

  const Option = Select.Option;

  const participantArray = Array.from({ length: participantsNumber ?? 0 }, (_, index) => index).map(
    (el: any, index: number) => {
      if (values[props.name]?.additionalPerson?.length !== 0) {
        return values[props.name]?.additionalPerson?.find(
          (el1: AdditionalPersonDto, index1: number) => {
            if (index === index1) {
              return (el = el1);
            }
          }
        );
      } else {
        return;
      }
    }
  );

  useEffect(() => {
    let values = props.form.getFieldValue('accommodations');
    values = values.map((item: AccommodationDto, index: number) => {
      if (index === props.name) {
        return (item = {
          ...item,
          additionalPerson: participantArray,
          numberOfDays: numberOfNights,
          dateFrom: dateStart,
          // priceOfAccommodation: !showPriceForPerson
          //   ? numberOfNights === undefined
          //     ? priceOfAccommodation
          //     : Number(priceOfAccommodation) * numberOfNights
          //   : numberOfNights === undefined
          //   ? roomPriceForPerson[`priceFor${participantsNumber}Person`]
          //   : Number(roomPriceForPerson[`priceFor${participantsNumber}Person`]) * numberOfNights,
          priceOfAccommodation:
            numberOfNights !== undefined &&
            participantsNumber !== undefined &&
            numberOfNights !== 0 &&
            roomPriceForPerson
              ? roomPriceForPerson[`priceFor${numberOfNights}Night`]
              : 0,
          dateTo: dateEnd,
          roomId: uuidv4()
        });
      } else {
        return item;
      }
    });

    props.form.setFieldValue('accommodations', values);
  }, [participantsNumber, numberOfNights, roomPriceForPerson]);

  useEffect(() => {
    if (props?.oneEvent === undefined) return;
    if (Object.keys(props?.oneEvent).length === 0) return;
    props.oneEvent?.accommodations?.map((el: AccommodationDto) => {
      if (values[props.name]?.roomType) {
        el.roomTypes.find((elRoom: EventXRoomTypeCreate) => {
          if (elRoom.id === values[props.name]?.roomType) {
            setRoomCapacity(elRoom.roomType.capacity);
            setMax(elRoom.roomType.capacity);
          }
        });
      }

      if (el.id === values[props.name]?.accommodationId) {
        setId(el.id);
        props.form.setFieldValue('accommodations', values);
        // if (props.individual) {
        //   props.handleGetData();
        // }
        setNewData(true);
      }
    });
    const mapOptions = props.oneEvent.accommodations.map((el: AccommodationDto) => {
      return { label: el.name, value: el.name, id: el.id };
    });
    setAllAccommodationOption(mapOptions);
  }, [props.oneEvent]);

  useEffect(() => {
    if (!newData) return;

    props.oneEvent?.accommodations?.map((el: AccommodationDto) => {
      if (el.id === id) {
        const data: any = el.roomTypes.map((el: EventXRoomTypeCreate) => {
          return { label: el.name, value: el.name, id: el.id };
        });
        setRoomTypesOptions(data);
        setNewData(false);
      }
    });
  }, [newData]);

  useEffect(() => {
    let totalPrice = 0;
    values.map((el: any) => {
      if (el?.priceOfAccommodation) {
        totalPrice = totalPrice + Number(el.priceOfAccommodation);
      }

      el?.additionalPerson?.map((el1: any) => {
        if (el1?.type === 'Učesnik') {
          return (totalPrice = totalPrice + props.registrationFee);
        }
      });
    });

    props.handleTotalPrice(totalPrice);
    props.handleGetPriceData(false);
  }, [values, props.getNewPriceData]);

  const handleDropdown = (e: string) => {
    let fieldValue = props.form.getFieldValue('accommodations');

    fieldValue = fieldValue.map((item: AccommodationDto, index: number) => {
      if (index === props.name) {
        return (item = {
          ...item,
          roomType: undefined,
          parkingPlaces: undefined,
          priceOfAccommodation: undefined,
          note: undefined
        });
      } else {
        return item;
      }
    });

    props.form.setFieldValue('accommodations', fieldValue);
    setId(e);
    setNewData(true);
  };

  useEffect(() => {
    const numberOfPersonsArray: any = [];
    for (let i = min; i <= max; i++) {
      numberOfPersonsArray.push({ label: i, value: i });
    }
    setNumberOfPersons(numberOfPersonsArray);
  }, [max]);

  const handleNewParticipantNumber = (numberOfPersons: number) => {
    setParticipantsNumber(numberOfPersons);
    const newValues = values.map((item: AccommodationDto, index: number) => {
      if (index === props.name) {
        return (item = {
          ...item,
          numberOfPersons: numberOfPersons
        });
      } else {
        return item;
      }
    });

    props.form.setFieldValue('accommodations', newValues);
  };

  const handleRoomType = (roomTypeId: string) => {
    let fieldValue = props.form.getFieldValue('accommodations');

    let singleRoomType: EventXRoomTypeCreate | any = undefined;

    let maxCapacity: any = 0;

    // let roomPrice: any = roomPriceForPerson[`priceFor${participantsNumber}Person`];

    props.oneEvent?.accommodations?.find((el: AccommodationDto) => {
      if (el.id === id) {
        el.roomTypes.find((elRoom: EventXRoomTypeCreate) => {
          if (elRoom.id === roomTypeId) {
            setRoomCapacity(elRoom.roomType.capacity);
            maxCapacity = elRoom.roomType.capacity;
            setMax(elRoom.roomType.capacity);
            return (singleRoomType = elRoom);
          }
        });
      }
    });

    if (fieldValue[props.name].numberOfPersons > maxCapacity) {
      setOpenAlertModal(true);

      fieldValue = fieldValue.map((item: AccommodationDto, index: number) => {
        if (index === props.name) {
          return (item = {
            ...item,
            roomType: ''
          });
        } else {
          return item;
        }
      });
      setMax(newMaxCapacity);
      setDisableNumberOfPerson(true);

      return props.form.setFieldValue('accommodations', fieldValue);
    }

    setNewMaxCapacity(maxCapacity);
    setDisableNumberOfPerson(false);

    props.oneEvent?.roomTypesForEvent.find((el: any) => {
      if (el.id === roomTypeId) {
        if (el.priceForNight) {
          setRoomPriceForPerson(el.priceForNight);
        }
      }
    });

    // props.oneEvent?.roomTypesForEvent.find((el: any) => {
    //   if (el.id === roomTypeId) {
    //     if (el.priceForPerson) {
    //       setShowPriceForPerson(el.priceForPerson);
    //       const price = el.price;
    //       delete price.priceForRoom;
    //       setRoomPriceForPerson(price);
    //     } else {
    //       setShowPriceForPerson(false);
    //       roomPrice = el.price.priceForRoom;
    //     }
    //   }
    // });

    // setPriceOfAccommodation(roomPrice);

    fieldValue = fieldValue.map((item: AccommodationDto, index: number) => {
      if (index === props.name) {
        return (item = {
          ...item,
          note: singleRoomType?.note,
          parkingPlaces: singleRoomType?.parkingPlace
          // priceOfAccommodation: roomPrice
        });
      } else {
        return item;
      }
    });

    props.form.setFieldValue('accommodations', fieldValue);

    if (props.individual) {
      if (props.paramsId === undefined) {
        if (participantsNumber === undefined || participantsNumber === 0) {
          setParticipantsNumber(1);
        } else {
          return;
        }
      } else {
        setParticipantsNumber(values[0]?.additionalPerson?.length);
      }
      return props.handleGetData();
    }
  };

  const handleDisabledDates = (current: any) => {
    if (props?.oneEvent?.date) {
      const from = props.oneEvent.date[0].$d;
      const to = props.oneEvent.date[1].$d;

      const newDateFrom = moment(from, 'DD-MM-YYYY').subtract(1, 'days');
      const newDateTo = moment(to, 'DD-MM-YYYY').add(1, 'days');
      return newDateFrom >= current.$d || newDateTo <= current.$d;
    } else {
      return false;
    }
  };

  const handleTotalParticipants = (e: ChangeEvent<HTMLInputElement>) => {
    if (Number(e) < participantsNumber) {
      setNewPersonNumber(e);
      return setOpenViewModal(true);
    }
    const value = Math.max(min, Math.min(max, Number(e)));
    return setParticipantsNumber(value);
  };

  const handleRangePicker = (e: any) => {
    const startDate = e[0].$d;
    const endDate = e[1].$d;
    setDateStart(moment(startDate).format('DD/MM/YYYY'));
    setDateEnd(moment(endDate).format('DD/MM/YYYY'));

    const timeDifference = endDate.getTime() - startDate.getTime();

    const dayDifference = timeDifference / (1000 * 3600 * 24);

    setNumberOfNights(dayDifference);
  };

  return (
    <div className="add-new-accommodation">
      <div className="inner-container">
        <div className="header-accommodation">
          <p className="accommodation-number">{`Smeštaj - ${props.name + 1}`}</p>
        </div>
        <Form.Item
          className="selection"
          name={[props.name, 'accommodationId']}
          label="Smeštaj:"
          rules={
            props.needAccommodation
              ? [
                  {
                    required: true,
                    message: 'Potrebno je uneti smeštaj!'
                  }
                ]
              : []
          }>
          <Select onChange={handleDropdown} placeholder="Izaberite smeštaj">
            {allAccommodationOption.map((el: AccommodationListDto, i: number) => (
              <Option key={el.id} value={el.id}>
                {el.value}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <div className="field-box-first">
          <div className="form-input-first">
            <Form.Item
              className="inner-selection"
              name={[props.name, 'roomType']}
              label="Vrsta smeštaja:"
              rules={
                props.needAccommodation
                  ? [
                      {
                        required: true,
                        message: 'Potrebno je uneti vrstu smeštaja!'
                      }
                    ]
                  : []
              }>
              <Select onChange={handleRoomType} placeholder="Vrsta smeštaja">
                {roomTypesOptions.map((el: RoomTypeListDto, i: number) => (
                  <Option key={el.id} value={el.id}>
                    {el.value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            {/* <p className="input-note">
              Prikaz tipova sobe pogledajte u “
              <Link to={'/accommodation'} className="span-wrapper">
                <span>SMEŠTAJ</span>
              </Link>
              ”
            </p> */}
          </div>
          <Form.Item
            {...props.restField}
            className={`${
              disableNumberOfPerson || (watchRoomtype && watchRoomtype !== '')
                ? 'form-input-second'
                : 'form-input-second-disabled'
            }`}
            name={[props.name, 'numberOfPersons']}
            label="Broj osoba:"
            rules={[
              {
                validateTrigger: 'onSubmit',
                validator: async (_, value) => {
                  if (roomCapacity !== value) {
                    return Promise.reject(new Error(`Minimalan broj osoba ${roomCapacity}!`));
                  } else {
                    return Promise.resolve();
                  }
                }
              }
            ]}>
            <Select onChange={handleTotalParticipants} placeholder="Broj osoba">
              {numberOfPersons.map((el: RoomTypeListDto, i: number) => (
                <Option key={el.value} value={el.value}>
                  {el.value}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>

        <div className="field-box-second">
          <Form.Item
            name={[props.name, 'date']}
            className="form-input-first"
            label="Odabrani datumi:"
            rules={
              props.needAccommodation
                ? [
                    {
                      required: true,
                      message: 'Potrebno je uneti datum!'
                    },
                    {
                      validateTrigger: 'onSubmit',
                      validator: async (_, value) => {
                        if (numberOfNights < 1) {
                          return Promise.reject(new Error(`Minimalan broj dana je 1!`));
                        } else {
                          return Promise.resolve();
                        }
                      }
                    }
                  ]
                : []
            }>
            <RangePicker
              disabledDate={handleDisabledDates}
              onChange={handleRangePicker}
              defaultPickerValue={[
                dayjs(props.oneEvent.date[0].$d),
                dayjs(props.oneEvent.date[0].$d)
              ]}
              showTime={false}
              format="YYYY-MM-DD"
            />
          </Form.Item>
          <Form.Item
            {...props.restField}
            className="form-input-second"
            name={[props.name, 'numberOfDays']}
            label="Broj noćenja:">
            <Input type="number" className="input-label" placeholder="Broj noćenja" />
          </Form.Item>
        </div>
        <div className="field-box-third">
          <Form.Item
            {...props.restField}
            className="form-input-first"
            name={[props.name, 'priceOfAccommodation']}
            label="Ukupna cena smeštaja:">
            <InputNumber
              style={{ width: '100%' }}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
            />
          </Form.Item>

          {values[props.name]?.parkingPlaces ? (
            <Form.Item
              {...props.restField}
              name={[props.name, 'parkingPlaces']}
              className="form-input-second"
              valuePropName="checked"
              label="Obezbeđeno parking mesto">
              <Checkbox className="input-label" type="checkbox" />
            </Form.Item>
          ) : (
            <div className="form-input-second">
              <p>Obezbeđeno parking mesto</p>
            </div>
          )}
        </div>
        <div className="field-box-four">
          {/* <Form.Item
            {...props.restField}
            className="form-input-first"
            name={[props.name, 'note']}
            label="Dodatna napomena:">
            <Input className="input-label" />
          </Form.Item> */}
          <div className="btn-container">
            <div
              className="btn"
              onClick={() => {
                props.handleGetPriceData(true);
                props.remove(props.name);
              }}>
              <img src={Bin} />
            </div>
          </div>
        </div>
        <div className="participants-list">
          <Form.List name={[props.name, 'additionalPerson']}>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space key={key} className="list-of-data" align="baseline">
                    <NewParticipant
                      form={props.form}
                      accommodation={true}
                      accommodationName={props.name}
                      individual={props.individual}
                      name={name}
                      restField={{ ...restField }}
                      remove={remove}
                      handleGetPriceData={props.handleGetPriceData}
                    />
                  </Space>
                ))}
              </>
            )}
          </Form.List>
        </div>
      </div>
      <Modal
        open={openViewModal}
        title={`Potvrda brisanja`}
        onCancel={() => {
          handleNewParticipantNumber(participantsNumber);
          setOpenViewModal(false);
        }}
        onOk={() => setOpenViewModal(false)}
        footer={[
          <Button
            className="btn-primary"
            key="submit"
            type="primary"
            onClick={() => {
              handleNewParticipantNumber(newPersonNumber);
              setOpenViewModal(false);
            }}>
            Potvrdi
          </Button>,
          <Button
            className="btn-primary"
            key="submit1"
            type="primary"
            onClick={() => {
              handleNewParticipantNumber(participantsNumber);
              setOpenViewModal(false);
            }}>
            Zatvori
          </Button>
        ]}>
        <p className="notification-content">{`Da li ste sigurni da zelite da nastavite?`}</p>
      </Modal>
      <Modal
        open={openAlertModal}
        title={`Obavestenje`}
        onCancel={() => setOpenAlertModal(false)}
        onOk={() => setOpenAlertModal(false)}
        footer={[
          <Button
            className="btn-primary"
            key="submit1"
            type="primary"
            onClick={() => {
              setOpenAlertModal(false);
            }}>
            Zatvori
          </Button>
        ]}>
        <p className="notification-content">{`Izabrali ste sobu sa kapacitetom koji je manji od unetog broja osoba u jednoj sobi. Smanjite broj osoba u jednoj sobi ili izaberite drugu sobu!`}</p>
      </Modal>
    </div>
  );
};

export default AddNewAccommodation;
