import { axiosInstance } from '../AxiosDefaults';

export const validateRecaptcha = (response: string): Promise<any> => {
  return axiosInstance.post(
    `/recaptcha`,
    {
      response
    },
    {
      headers: { 'Content-Type': 'application/json' }
    }
  );
};
