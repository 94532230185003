import archive1 from '../assets/archive1.png';
import archive2 from '../assets/archive2.jpg';
import archive3 from '../assets/archive3.png';

export const ArchiveData: any = [
  {
    image: archive2,
    title: 'Srpski dani osiguranja 2022.',
    text: 'U organizaciji Udruženja osiguravača Srbije održani su 6. Srpski dani osiguranja, pod motom "Nije sve u svoje vreme, već sve u tvoje vreme". Konferencija je i ove godine okupila vodeće ljude struke iz Srbije i regiona, predstavnike regulatornog tela...',
    imageArray: [],
    id: 1,
    link: 'https://uos.rs/sdo-2022-srpski-dani-osiguranja-cir/'
  },
  {
    image: archive1,
    title: 'Srpski dani osiguranja 2021.',
    text: 'U organizaciji Udruženja osiguravača Srbije održani su 5. Srpski dani osiguranja, pod motom "Tempora mutantur - vremena se menjaju". Konferencija je i ove godine okupila vodeće ljude struke iz Srbije i regiona, predstavnike regulatornog tela...',
    imageArray: [],
    id: 2,
    link: 'https://uos.rs/sdo-2021-5-srpski-dani-osiguranja-cir/'
  },
  {
    image: archive3,
    title: 'Srpski dani osiguranja 2020.',
    text: 'U organizaciji Udruženja osiguravača Srbije održani su 4. Srpski dani osiguranja.',
    imageArray: [],
    id: 3,
    link: 'https://uos.rs/%d1%81%d1%80%d0%bf%d1%81%d0%ba%d0%b8-%d0%b4%d0%b0%d0%bd%d0%b8-%d0%be%d1%81%d0%b8%d0%b3%d1%83%d1%80%d0%b0%d1%9a%d0%b0-2020/'
  },
  {
    image: archive3,
    title: 'Srpski dani osiguranja 2019.',
    text: 'U organizaciji Udruženja osiguravača Srbije održani su 3. Srpski dani osiguranja.',
    imageArray: [],
    id: 4,
    link: 'https://uos.rs/%d1%81%d1%80%d0%bf%d1%81%d0%ba%d0%b8-%d0%b4%d0%b0%d0%bd%d0%b8-%d0%be%d1%81%d0%b8%d0%b3%d1%83%d1%80%d0%b0%d1%9a%d0%b0-2019/'
  },
  {
    image: archive3,
    title: 'Srpski dani osiguranja 2018.',
    text: 'U hotelu "Izvor" u Aranđelovcu, od 28-30. novembra 2018. godine održani su Drugi "Srpski dani osiguranja" u organizaciji udruženja osiguravača Srbije. Konferencija je okupila oko 300 stručnjaka iz zemlje i inostranstva, koji su, kroz dva okruga..."',
    imageArray: [],
    id: 5,
    link: 'https://uos.rs/%d1%81%d1%80%d0%bf%d1%81%d0%ba%d0%b8-%d0%b4%d0%b0%d0%bd%d0%b8-%d0%be%d1%81%d0%b8%d0%b3%d1%83%d1%80%d0%b0%d1%9a%d0%b0-2018/'
  },
  {
    image: archive3,
    title: 'Srpski dani osiguranja 2017.',
    text: 'Udruženje osiguravača Srbije organizovalo je od 28.-30. novembra 2017. godine konferenciju “Srpski dani osiguranja” koja je okupila oko 200 domaćih i stručnjaka iz inostranstva iz oblasti osiguranja i reosiguranja, predstavnika regulatornih tela...',
    imageArray: [],
    id: 6,
    link: 'https://uos.rs/%D1%81%D1%80%D0%BF%D1%81%D0%BA%D0%B8-%D0%B4%D0%B0%D0%BD%D0%B8-%D0%BE%D1%81%D0%B8%D0%B3%D1%83%D1%80%D0%B0%D1%9A%D0%B0-2017/'
  }
];
