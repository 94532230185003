import { useState, useEffect } from 'react';
import { Switch } from 'antd';
import LegalEntity from '../../components/LegalEntity/LegalEntity';
import Individual from '../../components/Individual/Individual';
import './SignUpform.scss';
import useFetchOneEvent from '../../customHooks/FetchOneEvent/FetchOneEvent';
import AgendaNavigation from '../../components/AgendaNavigation/AgendaNavigation';
import AgendaNavigationMobile from '../../components/AgendaNavigationMobile/AgendaNavigationMobile';
import { AgendaTabData } from '../../data/AgendaTabData';
import { currentEventId } from '../../config/config';

const SignUpform = () => {
  const [checked, setChecked] = useState<boolean>(false);
  const [showRegistration, setShowRegistration] = useState(true);
  const [idEvent, setIdEvent] = useState<string>(currentEventId);
  const [dataEvent] = useFetchOneEvent(idEvent);
  const [data, setData] = useState();

  const handleSwitchCase = (checked: boolean) => {
    setChecked(checked);
  };

  useEffect(() => {
    let totalRoomTypes = 0;
    const data = { ...dataEvent };
    const roomTypesForEventFilter = dataEvent?.accommodations.map((el: any) => {
      return {
        ...el,
        roomTypes: el?.roomTypes?.filter((el1: any) => {
          if (
            el1.totalNumberRoomForSite !== 0 &&
            el1.totalNumber > el1.reservedNumber + (el1.usedNumber ?? 0)
          ) {
            totalRoomTypes = totalRoomTypes + 1;
            return el1;
          }
        })
      };
    });

    data.accommodations = roomTypesForEventFilter;
    setData(data);

    if (roomTypesForEventFilter) {
      if (totalRoomTypes !== 0) {
        return setShowRegistration(true);
      } else {
        return setShowRegistration(false);
      }
    }
  }, [dataEvent]);

  return (
    <div className="sign-up-form">
      {showRegistration ? (
        <>
          <div className={'switch-box'}>
            <div className="inner">
              <button
                onClick={() => handleSwitchCase(false)}
                className={`btn-legal-entity ${checked && 'active'}`}>
                <p className="text">PRAVNO LICE</p>
              </button>
              <button
                onClick={() => handleSwitchCase(true)}
                className={`btn-legal-entity ${!checked && 'active'}`}>
                <p className="text">FIZIČKO LICE</p>
              </button>
            </div>
          </div>
          <p className={'text-blue'}>
            * Smeštaj u hotelu <a href="https://www.hotelzlatibor-resort.com/">“Zlatibor”</a> na
            bazi 2 puna pansiona i 1 polu pansiona podrazumeva (švedski sto doručak, ručak i
            večera), svečanu večeru sa muzičkim programom, koktel, kafe pauze, boravišnu taksu i
            osiguranje, korišćenje Magnesium Spa/ Welness/ Fitness centra u hotelu, unutrašnjih
            bazena i sauna, wireless interneta.
          </p>
          <div className={'img'}></div>
          <p className={'text-blue'}>
            ** Ukoliko Vaši podaci nisu uneti ispravno proces prijave neće moći da se uspešno
            završi.
          </p>
          <p className={'text-blue'}>*** Polja koja su oznacena * obavezno je popuniti.</p>
        </>
      ) : null}

      {showRegistration ? (
        <div>
          {checked ? (
            <Individual data={data} idEvent={idEvent} />
          ) : (
            <LegalEntity data={data} idEvent={idEvent} />
          )}
        </div>
      ) : (
        <p className={'info-text'}>Sve sobe su rezervisane!</p>
      )}
    </div>
  );
};

export default SignUpform;
