import { useState, useEffect } from 'react';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import './SignUpPage.scss';
import MobileHeaderSmall from '../../components/MobileHeaderSmall/MobileHeaderSmall';
import { Link } from 'react-router-dom';
import SignUpform from '../../components/SignUpform/SignUpform';

const SignUpPage = () => {
  const [data, setData] = useState<any>({});

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  return (
    <div className="sign-up-page">
      <Header main={false} />
      <MobileHeaderSmall main={false} />
      <div className="container">
        <p className="title">Prijava</p>
        <p className="subtitle">7. SRPSKI DANI OSIGURANJA</p>
        <p className="text">
          Molimo Vas da popunite sva obavezno navedena polja i ostavite svoje ispravne podatke radi
          lakše i brže potvrde prijave, rezervacije i potvrde rezervacije. Nakon popunjenih svih
          obaveznih polja putem mail adrese dobićete potvrdu o uspešno izvršenoj prijavi. Nakon
          obrede prijave i potvrde iste, dobićete podatke za uplatu i preostale korake koje je
          neophodno realizovati kako bi proces rezervacije bio u potpunosti potvrđen.
        </p>
        <div className="banner-box">
          <Link to={'/agenda'} className="banner-wrapper-left">
            <div className="left-banner">
              <div className="left-banner-inner">
                <p className="title">AGENDA 7. SRPSKI DANI OSIGURANJA</p>
              </div>
            </div>
          </Link>
          <Link to={'/accommodation'} className="banner-wrapper-right">
            <div className="right-banner">
              <div className="right-banner-inner">
                <p className="title">ZLATIBOR MOUNTAIN RESORT & SPA</p>
              </div>
            </div>
          </Link>
        </div>
      </div>
      <SignUpform />
      <Footer />
    </div>
  );
};

export default SignUpPage;
